import React from "react";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { HashLink } from "react-router-hash-link";
import model1 from "../../assets/Gallery (1).JPG";
import model2 from "../../assets/Gallery (2).JPG";
import model3 from "../../assets/Gallery (3).JPG";
import model4 from "../../assets/Gallery (4).JPG";
import model5 from "../../assets/Gallery (5).JPG";
import model6 from "../../assets/Gallery (6).JPG";
import model7 from "../../assets/Gallery (7).JPG";
import model8 from "../../assets/Gallery (8).JPG";
import model9 from "../../assets/Gallery (9).JPG";
import model10 from "../../assets/Gallery (10).JPG";
import model11 from "../../assets/Gallery (11).JPG";
import model12 from "../../assets/Gallery (12).JPG";
import model13 from "../../assets/Gallery (13).jpg";
import model14 from "../../assets/Gallery (14).JPG";
import model15 from "../../assets/Gallery (15).JPG";
import model16 from "../../assets/Gallery (16).JPG";
import model17 from "../../assets/Gallery (17).JPG";
import model18 from "../../assets/Gallery (18).JPG";
import model19 from "../../assets/Gallery (19).JPG";
import model20 from "../../assets/Gallery (20).JPG";
import model21 from "../../assets/Gallery (21).JPG";
import model22 from "../../assets/Gallery (22).JPG";
import model23 from "../../assets/Gallery (23).JPG";
import model24 from "../../assets/Gallery (24).JPG";
import model25 from "../../assets/Gallery (25).JPG";
import model26 from "../../assets/Gallery (26).jpg";
import model27 from "../../assets/Gallery (27).jpg";
import model28 from "../../assets/Gallery (28).jpg";
import model29 from "../../assets/Gallery (29).jpg";
import model30 from "../../assets/Gallery (30).JPG";
import model31 from "../../assets/Gallery (31).JPG";
import model32 from "../../assets/Gallery (32).jpg";
import model33 from "../../assets/Gallery (33).jpg";
import model34 from "../../assets/Gallery (34).jpg";
import model35 from "../../assets/Gallery (35).JPG";
import model36 from "../../assets/Gallery (36).JPG";
import model37 from "../../assets/Gallery (37).JPG";
import model38 from "../../assets/Gallery (38).JPG";
import model39 from "../../assets/Gallery (39).jpg";
import model40 from "../../assets/Gallery (40).JPG";
import model41 from "../../assets/Gallery (41).JPG";
import model42 from "../../assets/Gallery (42).JPG";
import model43 from "../../assets/Gallery (43).JPG";
import model44 from "../../assets/Gallery (44).JPG";
import model45 from "../../assets/pink (1).jpg";
import model46 from "../../assets/pink (2).jpg";
import model47 from "../../assets/pink (3).jpg";
import model48 from "../../assets/pink (4).jpg";
import model49 from "../../assets/pink (5).jpg";
import model50 from "../../assets/pink (6).jpg";
import model51 from "../../assets/pink (7).jpg";
import model52 from "../../assets/pink (8).jpg";
import model53 from "../../assets/pink (9).jpg";
import model54 from "../../assets/pink (10).jpg";
import model55 from "../../assets/pink (11).jpg";
import model56 from "../../assets/pink (12).jpg";
import model57 from "../../assets/pink (13).jpg";
import model58 from "../../assets/pink (14).jpg";
import model59 from "../../assets/pink (15).jpg";
import model60 from "../../assets/pink (16).jpg";
import model61 from "../../assets/pink (17).jpg";
import model62 from "../../assets/pink (18).jpg";
import model63 from "../../assets/pink (19).jpg";
import model64 from "../../assets/pink (20).jpg";
import model65 from "../../assets/pink (21).jpg";
import model66 from "../../assets/pink (22).jpg";
import model67 from "../../assets/pink (23).jpg";
import model68 from "../../assets/pink (24).jpg";
import model69 from "../../assets/pink (25).jpg";
import model70 from "../../assets/pink (26).jpg";
import model71 from "../../assets/pink (27).jpg";
import model72 from "../../assets/pink (28).jpg";
import model73 from "../../assets/pink (29).jpg";
import model74 from "../../assets/pink (30).jpg";
import model75 from "../../assets/pink (31).jpg";
import model76 from "../../assets/pink (32).jpg";
import model77 from "../../assets/pink (33).jpg";
import model78 from "../../assets/pink (34).jpg";
import model79 from "../../assets/pink (35).jpg";
import model80 from "../../assets/pink (36).jpg";
import model81 from "../../assets/pink (37).jpg";
import model82 from "../../assets/pink (38).jpg";
import model83 from "../../assets/pink (39).jpg";
import model84 from "../../assets/pink (40).jpg";
import model85 from "../../assets/pink (41).jpg";
import model86 from "../../assets/pink (42).jpg";
import model87 from "../../assets/pink (43).jpg";
import model88 from "../../assets/pink (44).jpg";
import model89 from "../../assets/pink (45).jpg";
import model90 from "../../assets/pink (46).jpg";
import model91 from "../../assets/pink (47).jpg";
import model92 from "../../assets/pink (48).jpg";
import model93 from "../../assets/pink (49).jpg";
import model94 from "../../assets/pink (50).jpg";
import model95 from "../../assets/pink (51).jpg";
import model96 from "../../assets/pink (52).jpg";
import model97 from "../../assets/pink (53).jpg";
import model98 from "../../assets/pink (54).jpg";
import model99 from "../../assets/pink (55).jpg";
import model100 from "../../assets/pink (56).jpg";
import model101 from "../../assets/pink (57).jpg";
import model102 from "../../assets/pink (58).jpg";
import model103 from "../../assets/pink (59).jpg";
import model104 from "../../assets/pink (60).jpg";
import model105 from "../../assets/pink (61).jpg";

function Gallery() {
  const phoneNumber = "2430800";

  const handleCallButtonClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };
  return (
    <div id="gallery">
      <header className="App-header">
        <div className="container my-5">
          <div className="row">
            <div className="col">
            <Typography
              variant="h4"
              align="center"
              sx={{
                mb: 4,
                fontFamily: 'cursive',
                fontSize: '2.5rem',
                color: '#fff', // Set the color to white

              }}
            >
              Gallery
            </Typography>


            </div>
          </div>
          <div className="row">

          <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model45} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model46} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model47} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model48} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model49} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model50} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model51} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model52} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model53} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model54} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model55} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model56} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model57} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model58} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model59} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model60} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model61} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model62} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model63} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model64} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model65} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model66} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model67} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model68} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model69} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model70} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model71} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model72} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model73} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model74} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model75} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model76} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model77} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model78} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model79} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model80} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model81} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model82} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model83} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model84} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model85} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model86} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model87} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model88} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model89} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model90} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model91} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model92} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model93} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model94} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model95} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model96} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model97} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model98} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model99} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model100} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model101} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model102} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model103} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model104} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model105} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>

            <div className="col-md-3 mb-3">
            <div className="ratio ratio-1x1">
              <img src={model1} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
            </div>
            </div>
                
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model2} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model3} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model4} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model5} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model6} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model7} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model8} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model9} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model10} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
            <div className="ratio ratio-1x1">
              <img src={model11} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
            </div>
            </div>
                
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model12} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model13} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model14} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model15} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model16} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model17} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model18} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model19} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model20} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
            <div className="ratio ratio-1x1">
              <img src={model21} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
            </div>
            </div>
                
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model22} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model23} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model24} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model25} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model26} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model27} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model28} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model29} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model30} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
            <div className="ratio ratio-1x1">
              <img src={model31} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
            </div>
            </div>
                
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model32} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model33} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model34} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model35} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model36} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model37} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model38} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model39} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model40} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
            <div className="ratio ratio-1x1">
              <img src={model41} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
            </div>
            </div>
                
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model42} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model43} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model44} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="row">
        <div className="col-12 col-md-6">
          <Typography variant="h4" align="left" sx={{ mb: 5 }}>
            <span style={{ color: "#FF1493" }}>Pink Love</span>
          </Typography>
          <button
            className="btn btn-primary"
            style={{ backgroundColor: "blue" }}
            onClick={handleCallButtonClick}
          >
            Call Now
          </button>
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-end">
          <a
            href="https://www.facebook.com/pinklovefiji"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: "20px" }}
          >
            <FacebookIcon style={{ fontSize: "32px", color: "#3b5998" }} />
          </a>
          <a
            href="https://www.instagram.com/pinklovefiji/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: "20px" }}
          >
            <InstagramIcon style={{ fontSize: "32px", color: "#C13584" }} />
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ul className="nav justify-content-md-end">
            <li className="nav-item">
              <HashLink
                to="/#home"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Home
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#about"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                About
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#gallery"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Gallery
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#branches"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Branches
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#promotions"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Promotions
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#contact"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Contact
              </HashLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 text-center">
          <Typography variant="caption" color="textSecondary">
            <span>
              © Pink Love designed by Dev House ("It's a Wrap")
            </span>
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
