import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

function Branches() {
  return (
    <div id="branches">
      <header className="App-header">
      <Box sx={{ mb: 4 }}>
      <Typography variant="h4" align="center" sx={{ mb: 4 }}>
          Come Visit Us
      </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <Card sx={{ backgroundColor: "#df92a7" }}>
            <CardContent>
              <div className="d-flex align-items-center mb-3">
                <LocationOnIcon sx={{ fontSize: { xs: 30, md: 40 }, mr: 2 }} />
                <div>
                  <Typography variant="h6" component="h2">
                    Damodar City
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <AccessTimeIcon
                      sx={{ fontSize: { xs: 16, md: 20 }, mr: 1 }}
                    />
                    Monday - Wednesday: 10.00 am - 7.00 pm
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <AccessTimeIcon
                      sx={{ fontSize: { xs: 16, md: 20 }, mr: 1 }}
                    />
                    Thursday - Saturday: 10.00 am - 8.00 pm
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <AccessTimeIcon
                      sx={{ fontSize: { xs: 16, md: 20 }, mr: 1 }}
                    />
                    Sunday: 11.00 am - 5.00 pm
                  </Typography>
                </div>
              </div>
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    className="gmap_iframe"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    src="https://maps.google.com/maps?width=800&amp;height=600&amp;hl=en&amp;q=Damodar City Centre, Grantham Rd, Suva, Fiji&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    width="100%"
                    height="300"
                  ></iframe>
                </div>
              </div>
            </CardContent>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 2 }}>
              <Button
                variant="contained"
                component={Link}
                to="/damodar-city"
                color="primary"
              >
                View Prices
              </Button>
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Card sx={{ backgroundColor: "#df92a7" }}>
            <CardContent>
              <div className="d-flex align-items-center mb-3">
                <LocationOnIcon sx={{ fontSize: { xs: 30, md: 40 }, mr: 2 }} />
                <div>
                  <Typography variant="h6" component="h2">
                    Suva City
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <AccessTimeIcon
                      sx={{ fontSize: { xs: 16, md: 20 }, mr: 1 }}
                    />
                    Monday - Friday: 9.30 am - 5.30 pm
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <AccessTimeIcon
                      sx={{ fontSize: { xs: 16, md: 20 }, mr: 1 }}
                    />
                    Saturday: 9.30 am - 3.00 pm
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <AccessTimeIcon
                      sx={{ fontSize: { xs: 16, md: 20 }, mr: 1 }}
                    />
                    Sunday: Closed
                  </Typography>
                  
                </div>
              </div>
              <div className="mapouter">
                <div className="gmap_canvas">
                  <iframe
                    className="gmap_iframe"
                    frameBorder="0"
                    scrolling="no"
                    marginHeight="0"
                    marginWidth="0"
                    src="https://maps.google.com/maps?width=800&amp;height=600&amp;hl=en&amp;q=176%20Renwick%20Rd,%20Suva%20-%20City%20Center,%20Fiji&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                    width="100%"
                    height="300"
                  ></iframe>
                </div>
              </div>
            </CardContent>
            <Box sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 2 }}>
              <Button
                variant="contained"
                component={Link}
                to="/suva-city"
                color="primary"
              >
                View Prices
              </Button>
            </Box>
          </Card>
        </Grid>
      </Grid>
      </header>
    </div>
  );
}

export default Branches;
