import React from "react";
import model from "../../assets/promotion-7.jpg";
import Typography from "@mui/material/Typography";

function About() {
  return (
    <div id="about">
      <header className="App-header">
        <div className="container my-5">
          <div className="row">
          <Typography variant="h4" align="center" sx={{ mb: 4 }}>
          Welcome to PINK LOVE BEAUTY & NAIL SALON
          </Typography>
            <div className="col-md-6">
              <img
                src={model}
                alt="Pink Love Makeup Salon"
                className="img-fluid image-container"
                style={{ height: "100%" }}
              />
            </div>
            <div className="col-md-6">
              <div className="pl-md-5">
              
                <p className="lead">
                  Located conveniently in Damodar City & Renwick Road Suva, our
                  beauty & nail salon is one of the best salons in this area.
                  Pink Love Beauty & Nail Salon offers premier nails care and
                  beauty treatment services to satisfy your needs of enhancing
                  natural beauty and refreshing your day.{" "}
                </p>
                <p className="lead">
                  Our beauty & nail salon takes pride in providing our valued
                  customers all good services and top-high quality products as
                  well as materials. At Pink Love Beauty & Nail Salon, a wide
                  choice is provided to customers, so you can use any services
                  that you like. We provide a full range of services from
                  Manicure, Pedicure to Eyelash Extensions, Skin Care with Face
                  & Body Waxing.
                </p>
                <p className="lead">
                  Come to our salon and experience our impeccable service that
                  nurtures the body, mind & spirit. Let’s book an appointment
                  and we will take care of the rest. It’s time to treat yourself
                  because you deserve it!
                </p>
                <p className="lead">
                  Pink Love Beauty & Nail Salon looks forward to seeing you in
                  our beauty & nail salon to allow us the opportunity to bring
                  out the beauty in you!
                </p>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
}

export default About;
