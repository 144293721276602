import React from "react";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { HashLink } from "react-router-hash-link";

function Links() {
  const phoneNumber = "2430800";

  const handleCallButtonClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  };

  return (
    
    <div id="links" className="container-fluid" style={{ marginBottom: "20px", marginTop: "20px" }}>
      <div className="row">
        <div className="col-12 col-md-6">
          <Typography variant="h4" align="left" sx={{ mb: 5 }}>
            <span style={{ color: "#FF1493" }}>Pink Love</span>
          </Typography>
          <button
            className="btn btn-primary"
            style={{ backgroundColor: "blue" }}
            onClick={handleCallButtonClick}
          >
            Call Now
          </button>
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-end">
          <a
            href="https://www.facebook.com/pinklovefiji"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: "20px" }}
          >
            <FacebookIcon style={{ fontSize: "32px", color: "#3b5998" }} />
          </a>
          <a
            href="https://www.instagram.com/pinklovefiji/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: "20px" }}
          >
            <InstagramIcon style={{ fontSize: "32px", color: "#C13584" }} />
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ul className="nav justify-content-md-end">
            <li className="nav-item">
              <HashLink
                to="/#home"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Home
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#about"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                About
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#gallery"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Gallery
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#branches"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Branches
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#promotions"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Promotions
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#contact"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Contact
              </HashLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 text-center">
          <Typography variant="caption" color="textSecondary">
            <span>
              © Pink Love designed by Dev House ("It's a Wrap")
            </span>
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default Links;
