import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./components/home/Home";
import About from "./components/home/About";
import Branches from "./components/home/Branches";
import Contact from "./components/home/Contact";
import Header from "./components/shared/Header";
import Promotions from "./components/home/Promotions";
import DamodarCity from "./components/prices/DamodarCity";
import SuvaCity from "./components/prices/SuvaCity";
import Images from "./components/images/Images";
import Gallery from "./components/home/Gallery";
import Links from "./components/home/Links";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/damodar-city" element={<DamodarCity />} />
        <Route path="/suva-city" element={<SuvaCity />} />
        <Route path="images" element={<Images />} />
      </Routes>
    </BrowserRouter>
  );
}

function HomePage() {
  return (
    <>
      <Home />
      <About />
      <Promotions />
      <Gallery />     
      <Contact />
      <Branches />
      <Links />
    </>
  );
}

export default App;
