import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import { HashRouter as Router } from "react-router-dom";
import logo from "../../assets/logo.png";
import "./Header.css";

function Header() {
  const [showMenu, setShowMenu] = useState(false);

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const closeMenu = () => {
    setShowMenu(false);
  };

  return (
      <div className="header position-sticky">
        <nav className="navbar navbar-expand-lg navbar-light p-0">
          <div className="container-fluid navbar_rounded bg-light">
            <Link to="/#home" className="navbar-brand">
              <div className="logo">
                <img
                  src={logo}
                  className="header_icon"
                  height={50}
                  alt="logo"
                />
              </div>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              onClick={toggleMenu}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`collapse navbar-collapse ${showMenu ? "show" : ""}`}
            >
              <ul className="navbar-nav ms-auto">
                <li className="nav-item">
                  <HashLink
                    to="/#home"
                    smooth
                    onClick={closeMenu}
                    className="nav-link"
                    style={{ color: "#FF1493", fontFamily: "cursive" }}
                  >
                    Home
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink
                    to="/#about"
                    smooth
                    onClick={closeMenu}
                    className="nav-link"
                    style={{ color: "#FF1493", fontFamily: "cursive" }}
                  >
                    About
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink
                    to="/#promotions"
                    smooth
                    onClick={closeMenu}
                    className="nav-link"
                    style={{ color: "#FF1493", fontFamily: "cursive" }}
                  >
                    Promotions
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink
                    to="/#gallery"
                    smooth
                    onClick={closeMenu}
                    className="nav-link"
                    style={{ color: "#FF1493", fontFamily: "cursive" }}
                  >
                    Gallery
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink
                    to="/#contactForm"
                    smooth
                    onClick={closeMenu}
                    className="nav-link"
                    style={{ color: "#FF1493", fontFamily: "cursive" }}
                  >
                    Contact
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink
                    to="/#branches"
                    smooth
                    onClick={closeMenu}
                    className="nav-link"
                    style={{ color: "#FF1493", fontFamily: "cursive" }}
                  >
                    Branches
                  </HashLink>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>

  );
}

export default Header;
