import React from 'react';
import promotionImage from '../../assets/promotion-2.jpg';
import promotionPic from '../../assets/promotion-6.jpg';
import promo from '../../assets/promo.jpg';
import md from '../../assets/md.jpg';

const Promotions = () => {
  return (
    <section id="promotions" className="bg-light py-5">
      
      <div className="container">
        <h2 className="text-center mb-4">Promotions</h2>
        <div className="row">
          <div className="col-md-3 mb-4">
            <div className="card h-100 d-flex flex-column">
              <img
                src={promotionImage}
                className="card-img-top flex-grow-1"
                alt="Summer Collection"
                style={{ objectFit: 'cover' }}
              />
              <div className="card-body">
                <h3 className="card-title mb-3">Sunday Specials</h3>
                <p className="card-text mb-4">
                  Get 10% on services above $25
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div className="card h-100 d-flex flex-column">
              <img
                src={promotionPic}
                className="card-img-top flex-grow-1"
                alt="Birthday"
                style={{ objectFit: 'cover' }}
              />
              <div className="card-body">
                <h3 className="card-title mb-3">Birthday Specials</h3>
                <p className="card-text mb-4">
                  Spend $25.00 & above and get 20% off on your Birthday
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div className="card h-100 d-flex flex-column">
              <img
                src={md}
                className="card-img-top flex-grow-1"
                alt="Summer Collection"
                style={{ objectFit: 'cover' }}
              />
              <div className="card-body">
                <h3 className="card-title mb-3">Mother's Day Specials</h3>
                <p className="card-text mb-4">
                  Get 10% off on $25 & above
                </p>
              </div>
            </div>
          </div>
          <div className="col-md-3 mb-4">
            <div className="card h-100 d-flex flex-column">
              <img
                src={promo}
                className="card-img-top flex-grow-1"
                alt="Birthday"
                style={{ objectFit: 'cover' }}
              />
              <div className="card-body">
                <h3 className="card-title mb-3">Holiday Specials</h3>
                <p className="card-text mb-4">
                  Book a group of 4 or more and get 15% off on services
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Promotions;
