import React from "react";
import { Link } from "react-router-dom";
import { Button, Box } from "@mui/material";
import model from "../../assets/pinklove_home.jpg"; // Import the image

function Home() {
  return (
    <div
      id="home"
      className="container-fluid py-5"
      style={{
        backgroundImage: `url(${model})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: "100vh",
      }}
    >
      <div className="row justify-content-center align-items-center h-100">
        <div
          className="col-10 col-sm-8 col-md-6 col-lg-4 text-center bg-white p-4 rounded"
          style={{ color: "#e75480" }}
        >
          <h1 className="mb-4">Pink Love Beauty Salon</h1>
          <p className="mb-0">Beauty Services</p>
          <p className="mb-0">Threading</p>
          <p className="mb-0">Waxing</p>
          <p className="mb-0">Facials</p>
          <p className="mb-0">Manicure</p>
          <p className="mb-0">Pedicure</p>
          <p className="mb-0">Eyelashes</p>

          {/* First Button */}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 2, mb: 1 }}>
            <Button
              variant="contained"
              component={Link}
              to="/damodar-city"
              style={{ backgroundColor: "#e75480", color: "white", width: "150px" }}
            >
              DC Prices
            </Button>
          </Box>

          {/* Second Button */}
          <Box sx={{ display: "flex", justifyContent: "center", mt: 1, mb: 1 }}>
            <Button
              variant="contained"
              component={Link}
              to="/suva-city"
              style={{ backgroundColor: "#e75480", color: "white", width: "150px" }}
            >
              SUVA Prices
            </Button>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default Home;
