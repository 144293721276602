import React from "react";
import { Container, Table } from "react-bootstrap";
import Typography from "@mui/material/Typography";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { HashLink } from "react-router-hash-link";

function DamodarCity() {
  const phoneNumber = "2430800";

  const handleCallButtonClick = () => {
    window.location.href = `tel:${phoneNumber}`;
  }
  return (
    <div id="contact">
      <header className="App-header">
        <br></br>
      <Typography
              variant="h4"
              align="center"
              sx={{
                mb: 4,
                fontFamily: 'cursive',
                fontSize: '2.5rem',
                color: '#fff', // Set the color to white

              }}
            >
              Damodar City
       </Typography>
        <Container>
          <br></br>
        <h3>Waxing Services</h3>
          <Table striped bordered hover>         
            <thead>
              <tr>
                <th>Service</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Brow Wax</td>
                <td>$8.00</td>
              </tr>
              <tr>
                <td>Lip Wax</td>
                <td>$4.00</td>
              </tr>
              <tr>
                <td>Brow/Lip/Chin Wax</td>
                <td>from $15.00</td>
              </tr>
              <tr>
                <td>Brow/Lip/Chin Wax (Heavy)</td>
                <td>from $18.00</td>
              </tr>
              <tr>
                <td>Brow/Lip/Sides Wax</td>
                <td>from $18.00</td>
              </tr>
              <tr>
                <td>Chin Wax</td>
                <td>from $6.00</td>
              </tr>
              <tr>
                <td>Full Face Wax</td>
                <td>from $30.00</td>
              </tr>
              <tr>
                <td>Full Face/Neck Wax</td>
                <td>from $35.00</td>
              </tr>
              <tr>
                <td>Underarms Wax</td>
                <td>from $15.00</td>
              </tr>
              <tr>
                <td>1/2 Arms Wax</td>
                <td>from $20.00</td>
              </tr>
              <tr>
                <td>Full Arms Wax</td>
                <td>from $30.00</td>
              </tr>
              <tr>
                <td>Nipple Wax</td>
                <td>from $10.00</td>
              </tr>
              <tr>
                <td>Chest Wax</td>
                <td>from $25.00</td>
              </tr>
              <tr>
                <td>Stomach Wax</td>
                <td>from $25.00</td>
              </tr>
              <tr>
                <td>Full Back Wax</td>
                <td>from $50.00</td>
              </tr>
              <tr>
                <td>Buttocks Wax</td>
                <td>from $30.00</td>
              </tr>
              <tr>
                <td>Bikini Wax</td>
                <td>from $40.00</td>
              </tr>
              <tr>
                <td>Brazilian Wax</td>
                <td>from $55.00</td>
              </tr>
              <tr>
                <td>1/2 Leg Wax</td>
                <td>from $30.00</td>
              </tr>
              <tr>
                <td>3/4 Leg Wax</td>
                <td>from $40.00</td>
              </tr>
              <tr>
                <td>Thighs Wax</td>
                <td>from $35.00</td>
              </tr>
              <tr>
                <td>Full Leg Wax</td>
                <td>from $55.00</td>
              </tr>
            </tbody>
          </Table>
        </Container>

        <div class="container">
        <br></br>
        <h3>Eyelash</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead class="thead-dark">
              <tr>
                <th>Services</th>
                <th>Duration</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Classic – Single Lashes </td>
                <td>1 Hour</td>
                <td>$65.00</td>
              </tr>
              <tr>
                <td>Natural </td>
                <td>1 Hour</td>
                <td>$55.00</td>
              </tr>
              <tr>
                <td>Hybrid</td>
                <td>1 Hour 30mins</td>
                <td>$80.00</td>
              </tr>
              <tr>
                <td>Volume</td>
                <td>2 Hours</td>
                <td>$100.00-$120.00</td>
              </tr>
              <tr>
                <td>Mega Volume</td>
                <td>3 Hours</td>
                <td>$120.00-$150.00</td>
              </tr>
              <tr>
                <td>Infills (1 week)</td>
                <td></td>
                <td>from $50.00</td>
              </tr>
              <tr>
                <td>Infills (2 weeks)</td>
                <td></td>
                <td>from $65.00</td>
              </tr>
              <tr>
                <td>Infills (3 weeks)</td>
                <td></td>
                <td>from $75.00</td>
              </tr>
              <tr>
                <td>Lash Removal</td>
                <td>30mins</td>
                <td>from $30.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container">
          <br></br>
          <h3>Threading</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Services</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Brow</td>
                <td>$6.00</td>
              </tr>
              <tr>
                <td>Lip</td>
                <td>$3.00</td>
              </tr>
              <tr>
                <td>Brow/Lip/Chin</td>
                <td>$10.00</td>
              </tr>
              <tr>
                <td>Brow/Lip/Chin (Heavy)</td>
                <td>$12.00</td>
              </tr>
              <tr>
                <td>Brow/Lip/Sides</td>
                <td>$12.00</td>
              </tr>
              <tr>
                <td>Chin</td>
                <td>from $4.00</td>
              </tr>
              <tr>
                <td>Full Face</td>
                <td>from $20.00</td>
              </tr>
              <tr>
                <td>Full Face/Neck</td>
                <td>from $25.00</td>
              </tr>
            </tbody>
          </table>

          <br></br>
          <h3>Additional Services</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Services</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Face Bleach (15mins)</td>
                <td>$15.00</td>
              </tr>
              <tr>
                <td>Eyebrow Tinting Only (15mins)</td>
                <td>$8.00</td>
              </tr>
              <tr>
                <td>Eyebrow Thread & Tint (15mins)</td>
                <td>$10.00</td>
              </tr>
              <tr>
                <td>Eyebrow Wax & Tint (15mins)</td>
                <td>$15.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container">
        <br></br>
        <h3>Gel Polish Manicure</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Services</th>
                <th>Duration</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td> File Buff & Polish</td>
                <td>30mins</td>
                <td>$25.00</td>
              </tr>
              <tr>
                <td>French Natural-Nail File Buff & Polish</td>
                <td>45mins</td>
                <td>$30.00</td>
              </tr>
              <tr>
                <td> Gel Polish Removal Buff Only</td>
                <td>10mins</td>
                <td>$10.00</td>
              </tr>
              <tr>
                <td>Gel Polish Removal Soak off</td>
                <td>20mins</td>
                <td>$15.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container my-5">
         <h3>Gel Polish Pedicure</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">Services</th>
                <th scope="col">Duration</th>
                <th scope="col">Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>File Buff & Polish</td>
                <td>30mins</td>
                <td>$30.00</td>
              </tr>
              <tr>
                <td>Gel Polish French Natural-Nail File Buff & Polish</td>
                <td>45mins</td>
                <td>$35.00</td>
              </tr>
              <tr>
                <td>Gel Polish Removal Buff Only</td>
                <td>10mins</td>
                <td>$10.00</td>
              </tr>
              <tr>
                <td>Gel Polish Removal Soak off</td>
                <td>20mins</td>
                <td>$15.00</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="container">
          <h3>Extras</h3>
          <table class="table table-bordered table-striped table-hover">
            <tbody>
              <tr>
                <td>Nail Art Free Hand</td>
                <td>From $2.00</td>
              </tr>
              <tr>
                <td>Stickers per nail</td>
                <td>From $2.00</td>
              </tr>
              <tr>
                <td>Rhinestones per nail</td>
                <td>From $2.00</td>
              </tr>
              <tr>
                <td>Encapsulated Art per nail</td>
                <td>From $3.00</td>
              </tr>
              <tr>
                <td>Glitter per nail</td>
                <td>From $2.00</td>
              </tr>
              <tr>
                <td>Nail Repair (Gel)</td>
                <td>From $5.00</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="container">
          <br></br>
          <h3>Acrylic Manicure Services</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Service</th>
                <th>Duration</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Acrylic Extensions French</td>
                <td>45 mins</td>
                <td>From $55.00</td>
              </tr>
              <tr>
                <td>Acrylic Extension & Gel Polish</td>
                <td>1 hour 30 mins</td>
                <td>From $65.00</td>
              </tr>
              <tr>
                <td>Acrylic Overlay & Gel Polish - Short</td>
                <td>1 hour</td>
                <td>From $45.00</td>
              </tr>
              <tr>
                <td>Acrylic Overlay & Gel Polish – Long</td>
                <td>1 hour</td>
                <td>From $50.00</td>
              </tr>
              <tr>
                <td>Infill Acrylic Extensions & Gel Polish</td>
                <td>1 hour</td>
                <td>From $50.00</td>
              </tr>
              <tr>
                <td>Infill Acrylic Overlay & Gel Polish</td>
                <td>45 mins</td>
                <td>From $35.00-$40.00</td>
              </tr>
              <tr>
                <td>Acrylic Removal Soak Off</td>
                <td>45 mins</td>
                <td>$30.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container">
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Extra</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Nail Shape per set (Coffin, Stiletto, Almond, Oval)</td>
                <td>$5.00</td>
              </tr>
              <tr>
                <td>Length per set</td>
                <td></td>
              </tr>
              <tr>
                <td>Medium to Long Length</td>
                <td>$5.00-$15.00</td>
              </tr>
              <tr>
                <td>Extra Long Length</td>
                <td>$20.00</td>
              </tr>
              <tr>
                <td>Nail Art Free Hand</td>
                <td>from $2.00</td>
              </tr>
              <tr>
                <td>Stickers per nail</td>
                <td>from $2.00</td>
              </tr>
              <tr>
                <td>Rhinestones per nail</td>
                <td>from $2.00</td>
              </tr>
              <tr>
                <td>Encapsulated Art per nail</td>
                <td>from $3.00</td>
              </tr>
              <tr>
                <td>Glitter per nail</td>
                <td>from $3.00</td>
              </tr>
              <tr>
                <td>Nail Repair (Acrylic)</td>
                <td>from $10.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container">
          <br></br>
          <h3>Acrylic Pedicure</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Service</th>
                <th>Duration</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Acrylic Extensions French</td>
                <td>45 minutes</td>
                <td>$60.00</td>
              </tr>
              <tr>
                <td>Acrylic Extension & Gel Polish</td>
                <td>1 hour 30 minutes</td>
                <td>$70.00</td>
              </tr>
              <tr>
                <td>Acrylic Overlay & Gel Polish</td>
                <td>1 hour</td>
                <td>$50.00</td>
              </tr>
              <tr>
                <td>Acrylic Removal Soak Off</td>
                <td>1 hour</td>
                <td>from $30.00</td>
              </tr>
            </tbody>
          </table>

          <br></br>
          <h3>Extras</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Service</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Nail Shape per set (Coffin, Almond, Oval)</td>
                <td>$5.00</td>
              </tr>
              <tr>
                <td>Length per set (Medium to Long Length)</td>
                <td>from $5.00 to $10.00</td>
              </tr>
              <tr>
                <td>Nail Art Free Hand</td>
                <td>from $2.00</td>
              </tr>
              <tr>
                <td>Stickers per nail</td>
                <td>from $2.00</td>
              </tr>
              <tr>
                <td>Rhinestones per nail</td>
                <td>from $2.00</td>
              </tr>
              <tr>
                <td>Encapsulated Art per nail</td>
                <td>from $3.00</td>
              </tr>
              <tr>
                <td>Glitter per nail</td>
                <td>from $3.00</td>
              </tr>
              <tr>
                <td>Nail Repair (Acrylic)</td>
                <td>from $10.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container">
          <br></br>
          <h3>Manicure Services</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Service</th>
                <th>Duration</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="fw-bold">Basic Manicure</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>File, Cut, Buff, Cuticle Maintenance</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td> Clear Coat</td>
                <td>20 mins</td>
                <td>$20.00</td>
              </tr>
              <tr>
                <td>Nail Polish</td>
                <td>20 mins</td>
                <td>$20.00</td>
              </tr>
              <tr>
                <td> Gel Polish</td>
                <td>30 mins</td>
                <td>$25.00</td>
              </tr>
              <tr>
                <td className="fw-bold">SPA Manicure</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Soak, File, Cut, Buff, Cuticle Maintenance, Hand Massage
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td> Clear Coat</td>
                <td>25 mins</td>
                <td>$30.00</td>
              </tr>
              <tr>
                <td> Nail Polish</td>
                <td>25 mins</td>
                <td>$30.00</td>
              </tr>
              <tr>
                <td> Gel Polish</td>
                <td>40 mins</td>
                <td>$45.00</td>
              </tr>
              <tr>
                <td className="fw-bold">Deluxe SPA Manicure</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Soak, File, Cut, Buff, Cuticle Maintenance, Hand Scrub, Mask,
                  Massage
                </td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Clear Coat</td>
                <td>35 mins</td>
                <td>$40.00</td>
              </tr>
              <tr>
                <td> Nail Polish</td>
                <td>35 mins</td>
                <td>$45.00</td>
              </tr>
              <tr>
                <td> Gel Polish</td>
                <td>60 mins</td>
                <td>$55.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container">
          <br></br>
          <h3>Pedicure Services</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Service</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="fw-bold">Basic Pedicure</td>
                <td></td>
              </tr>

              <tr>
                <td>File, Cut, Buff, Cuticle Maintenance</td>
              </tr>
              <tr>
                <td>Clear Coat (20mins)</td>
                <td>$25.00</td>
              </tr>
              <tr>
                <td>Nail Polish (20mins)</td>
                <td>$25.00</td>
              </tr>
              <tr>
                <td>Gel Polish (30mins)</td>
                <td>$30.00</td>
              </tr>
              <tr>
                <td className="fw-bold">SPA Pedicure</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Soak, File, Cut, Buff, Cuticle Maintenance, Hand Massage
                </td>
              </tr>
              <tr>
                <td>Clear Coat (25mins)</td>
                <td>$35.00</td>
              </tr>
              <tr>
                <td>Nail Polish (25mins)</td>
                <td>$40.00</td>
              </tr>
              <tr>
                <td>Gel Polish (40mins)</td>
                <td>$50.00</td>
              </tr>
              <tr>
                <td className="fw-bold">Deluxe SPA Pedicure</td>
                <td></td>
              </tr>
              <tr>
                <td>
                  Soak, File, Cut, Buff, Cuticle Maintenance, Callus Removal,
                  Foot Scrub, Mask, Massage
                </td>
              </tr>
              <tr>
                <td>Clear Coat (35mins)</td>
                <td>$45.00</td>
              </tr>
              <tr>
                <td>Nail Polish (35 mins)</td>
                <td>$50.00</td>
              </tr>
              <tr>
                <td> Gel Polish (60 mins)</td>
                <td>$60.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container">
          <br></br>
          <h2>Facials</h2>
          <h3>PURE FIJI</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Service</th>
                <th>Description</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Express Facial - (30 mins)</td>
                <td>
                  A quick cleanse, exfoliation, face massage, face steam and
                  extractions
                </td>
                <td>$30.00</td>
              </tr>
              <tr>
                <td>Deep Cleansing Facial - (1 hour)</td>
                <td>
                  Double cleanse, exfoliation, face massage, face steam and
                  extractions with the use of Vacuum suction, Face & Eye Mask
                </td>
                <td>$55.00</td>
              </tr>
              <tr>
                <td>Gold Facial (Anti-Aging) - (1 hour) KOREAN BEAUTY</td>
                <td>
                  Anti-aging facials are facials designed specifically to
                  rejuvenate mature skin. They do so by improving the thickness
                  and smoothness of skin, thereby reducing the appearance of
                  wrinkles, creases, and folds, as well as brightening up the
                  tone of the skin. Includes face cleanse, exfoliation, face
                  steam, extractions, face/eye masks and face/neck massage
                </td>
                <td>$65.00</td>
              </tr>
              <tr>
                <td>Micro Hydra Facial Treatment - (1 hour)</td>
                <td>
                  A HydraFacial is a multi step facial that involves cleansing,
                  exfoliation, pore extractions, and the infusion of customized
                  serums into the skin, for deep hydration and anti oxidant
                  protection. This gentle facial reguvenation treatment is safe
                  for all skin types and tones. <br />
                  HydraFacial benefits include a more even tone, reduced fine
                  lines and wrinkles, smaller pores, and clearer skin, with less
                  acne.
                </td>
                <td>$149.00</td>
              </tr>
              <tr>
                <td colspan="3">
                  <strong>Add Ons</strong>
                </td>
              </tr>
              <tr>
                <td>Radio Frequency - RF Treatment (15 Minutes)</td>
                <td>
                  Radio Frequency - RF Treatment or Radio Frequency(RF) skin
                  tigntening is a non-invasive way to achieve skin tightening
                  and make you look younger. This device will heat the deep skin
                  layers(dermis) of your body to produce collagen.
                </td>
                <td>$39.00</td>
              </tr>
              <tr>
                <td>LED Light Therapy - (20 minutes)</td>
                <td>
                  LED Light Therapy - using varing LED wavelengths, this
                  skincare technique purportedly helps treat acne, reduce
                  inflammation and promotes anti-aging effects. (Acne,
                  pigmentation, finelines, wrinkles, scars reduction, skin
                  reguvenation)
                </td>
                <td>$39.00</td>
              </tr>
              <tr>
                <td>Brightening Facial - (60 minutes)</td>
                <td>
                  A brightening facial is usually recommended for dull skil, or
                  skin with uneven pigmentation. This type of facial will
                  usually include a thorough exfoliation to buff away dead skin,
                  and an application of a specialist serum or cream that aims to
                  boost skin radiance and skin reguvenation - while it evens out
                  skin tone.
                </td>
                <td>$65.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container">
          <br></br>
          <h3>HIMALAY PRODUCTS (INDIAN HERBAL BASED)</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Facial</th>
                <th>Description</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Mini Facial (15 mins)</td>
                <td>
                  Facial cleanse, exfoliation, steam, extractions, and
                  moisturizer application customized to your skin type. Includes
                  removal of dead skin and whiteheads.
                </td>
                <td>$15.00</td>
              </tr>
              <tr>
                <td>Express Facial (30 mins)</td>
                <td>
                  Facial cleanse, exfoliation, steam, extractions, face massage
                  and moisturizer customized to your skin type. Includes removal
                  of dead skin, whiteheads, and blackheads.
                </td>
                <td>$20.00</td>
              </tr>
              <tr>
                <td>Deep Cleansing Facial (1 hour)</td>
                <td>
                  A double face cleanse, exfoliation, steam, masque and face
                  massage, extractions (if needed), and a moisturizer customized
                  to your skin type. Includes removal of dead skin, whiteheads,
                  and blackheads.
                </td>
                <td>$35.00</td>
              </tr>
              <tr>
                <td>Pimple/Acne Treatment (45 mins)</td>
                <td>
                  A double cleanse, deep exfoliation, steam, pimple/acne
                  treatment masque and face massage, blackheads, dead skin & zit
                  extractions, and a moisturizer customized to your skin type.
                </td>
                <td>$35.00</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="container">
          <br></br>
          <h3>Gelly Tips - Full Gel Extensions</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Service</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Gelly Tips Short & Gel Polish</td>
                <td>From $50.00</td>
              </tr>
              <tr>
                <td>Gelly Tips Medium & Gel Polish</td>
                <td>From $55.00</td>
              </tr>
              <tr>
                <td>Gelly Tips Long & Gel Polish</td>
                <td>From $60.00</td>
              </tr>
              <tr>
                <td>Gelly Tips & Nail Polish</td>
                <td>From $50.00</td>
              </tr>
              <tr>
                <th>Extras</th>
                <td></td>
              </tr>
              <tr>
                <td>Nail Shape per set (Coffin, Almond, Oval)</td>
                <td>$5.00</td>
              </tr>
              <tr>
                <td>Nail Art Free Hand</td>
                <td>From $2.00</td>
              </tr>
              <tr>
                <td>Stickers per nail</td>
                <td>From $2.00</td>
              </tr>
              <tr>
                <td>Rhinestones per nail</td>
                <td>From $2.00</td>
              </tr>
              <tr>
                <td>Encapsulated Art per nail</td>
                <td>From $3.00</td>
              </tr>
              <tr>
                <td>Glitter per nail</td>
                <td>From $3.00</td>
              </tr>
              <tr>
                <td>Nail Repair</td>
                <td>From $10.00</td>
              </tr>
              <tr>
                <td>Gelly Tips Removal</td>
                <td>From $25.00</td>
              </tr>
            </tbody>
          </table>


    
        </div>
        <div class="container">
          <br></br>
          <h3>Gel Extensions Manicure</h3>
          <table class="table table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Service</th>
                <th>Price</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Gel Extensions French (45mins)</td>
                <td>from $45.00</td>
              </tr>
              <tr>
                <td>Gel Extension & Gel Polish (1 Hour 30mins)</td>
                <td>from $50.00</td>
              </tr>
              <tr>
                <td>Gel Overlay & Gel Polish - Short (1 hour)</td>
                <td>from $35.00</td>
              </tr>
              <tr>
                <td>Gel Overlay & Gel Polish – Long (1 hour)</td>
                <td>from $40.00</td>
              </tr>
              <tr>
                <br />
              </tr>
              <tr>
                <td>Infill Gel Extensions & Gel Polish (1 Hour) </td>
                <td>from $35.00</td>
              </tr>
              <tr>
                <td>Infill Overlay & Gel Polish (45mins) </td>
                <td>from $30.00</td>
              </tr>
              <tr>
                <td>Gel Removal Soak Off (45mins)</td>
                <td>from $30.00</td>
              </tr>
              <tr>
                <th>Extras</th>
                <td></td>
              </tr>
              <tr>
                <td>Nail Shape per set (Coffin, Stiletto, Almond, Oval)</td>
                <td> $5.00</td>
              </tr>
              <tr>
                <th>Length per set</th>
                <td></td>
              </tr>
              <tr>
                <td>Medium to Long Length </td>
                <td>$5.00-$15.00</td>
              </tr>
              <tr>
                <td>Extra Long Length </td>
                <td> $20.00</td>
              </tr>
              <tr>
                <td>Nail Art Free Hand</td>
                <td>from $2.00</td>
              </tr>
              <tr>
                <td>Stickers per nail </td>
                <td>from $2.00</td>
              </tr>
              <tr>
                <td>Rhinestones per nail </td>
                <td>from $2.00</td>
              </tr>
              <tr>
                <td>Encapsulated Art per nail</td>
                <td>from $3.00</td>
              </tr>
              <tr>
                <td>Glitter per nail</td>
                <td>from $3.00</td>
              </tr>
              <tr>
                <td>Nail Repair (Acrylic)</td>
                <td>from $10.00</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="container mt-5">
        <h3 className="text-dark">Benefits of Gelly Tips:</h3>
        <ul className="text-dark">
          <li>Fast and easy system, takes about 45-60 minutes</li>
          <li>No fill required at two to three weeks, just apply a new set</li>
          <li>Gel extension manicures are a gentler alternative to acrylic extensions</li>
          <li>They last two to four weeks, and soak off like a regular gel manicure with acetone</li>
          <li>Thin and lightweight but also durable and strong</li>
          <li>Very natural and comfortable to wear</li>
          <li>Best for a client who has weak nails, is looking to grow out their natural nails and frequently changes their design or color</li>
        </ul>
      </div>
      <br></br>
      </header>
      <div className="row">
        <div className="col-12 col-md-6">
          <Typography variant="h4" align="left" sx={{ mb: 5 }}>
            <span style={{ color: "#FF1493" }}>Pink Love</span>
          </Typography>
          <button
            className="btn btn-primary"
            style={{ backgroundColor: "blue" }}
            onClick={handleCallButtonClick}
          >
            Call Now
          </button>
        </div>
        <div className="col-12 col-md-6 d-flex justify-content-end">
          <a
            href="https://www.facebook.com/pinklovefiji"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: "20px" }}
          >
            <FacebookIcon style={{ fontSize: "32px", color: "#3b5998" }} />
          </a>
          <a
            href="https://www.instagram.com/pinklovefiji/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ marginRight: "20px" }}
          >
            <InstagramIcon style={{ fontSize: "32px", color: "#C13584" }} />
          </a>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <ul className="nav justify-content-md-end">
            <li className="nav-item">
              <HashLink
                to="/#home"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Home
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#about"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                About
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#gallery"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Gallery
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#branches"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Branches
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#promotions"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Promotions
              </HashLink>
            </li>
            <li className="nav-item">
              <HashLink
                to="/#contact"
                smooth
                scroll={(el) => el.scrollIntoView({ behavior: "smooth", block: "start" })}
                className="nav-link"
                style={{ color: "#FF1493", fontFamily: "cursive" }}
              >
                Contact
              </HashLink>
            </li>
          </ul>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12 text-center">
          <Typography variant="caption" color="textSecondary">
            <span>
              © Pink Love designed by Dev House ("It's a Wrap")
            </span>
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default DamodarCity;
