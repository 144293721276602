import React from "react";
import emailjs from "emailjs-com";
import Typography from "@mui/material/Typography";
import { Button, TextField, Container, Box } from "@mui/material";
import animatedImage from "../../assets/contactPics.gif"; // Replace with your animated image file

const Contact = () => {
  const resetForm = () => {
    const form = document.getElementById("contactForm");
    form.reset();
  };

  const [formStatus, setFormStatus] = React.useState("Send");

  const onSubmit = (e) => {
    e.preventDefault();
    setFormStatus("Submitting...");
    const { name, phone, email, message } = e.target.elements;
    let conFom = {
      name: name.value,
      phone: phone.value,
      email: email.value,
      message: message.value,
    };

    // Send email using EmailJS
    emailjs
      .send("service_fytq6w2", "template_pusc53s", conFom, "Qen0OAFCnlXXP1WFV")
      .then((response) => {
        console.log("SUCCESS!", response.status, response.text);
        setFormStatus("Submitted!");
        // Display success message or perform other actions
        alert("Email sent successfully!");
      })
      .catch((error) => {
        console.error("FAILED...", error);
        setFormStatus("Error");
        // Display error message or perform other actions
        alert("Error sending email. Please try again.");
      });
  };

  return (
    <Container maxWidth="sm">
      <Box mt={8} textAlign="center">
        <img src={animatedImage} alt="Animated Image" style={{ width: "200px" }} /> {/* Add your animated image here */}
        <Typography variant="h4" sx={{ mb: 4, color: "#333" }}>
          Contact Us
        </Typography>
        <form id="contactForm" onSubmit={onSubmit}>
          <TextField
            id="name"
            label="Name"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              style: { color: "#333" },
            }}
            InputProps={{
              style: { color: "#333" },
            }}
          />
          <TextField
            id="phone"
            label="Phone"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              style: { color: "#333" },
            }}
            InputProps={{
              style: { color: "#333" },
            }}
          />
          <TextField
            id="email"
            label="Email"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            InputLabelProps={{
              style: { color: "#333" },
            }}
            InputProps={{
              style: { color: "#333" },
            }}
          />
          <TextField
            id="message"
            label="Message"
            variant="outlined"
            fullWidth
            margin="normal"
            required
            multiline
            rows={4}
            InputLabelProps={{
              style: { color: "#333" },
            }}
            InputProps={{
              style: { color: "#333" },
            }}
          />
          <Box display="flex" justifyContent="center" mt={4}>
            <Button type="submit" variant="contained" color="success" sx={{ mr: 2 }}>
              {formStatus}
            </Button>
            <Button type="button" variant="contained" color="secondary" onClick={resetForm}>
              Cancel
            </Button>
          </Box>
        </form>
        <br></br>
      </Box>
    </Container>
    
  );
};

export default Contact;
