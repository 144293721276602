import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import model45 from "../../assets/pink (1).jpg";
import model46 from "../../assets/pink (2).jpg";
import model47 from "../../assets/pink (3).jpg";
import model48 from "../../assets/pink (4).jpg";
import model49 from "../../assets/pink (5).jpg";
import model50 from "../../assets/pink (6).jpg";
import model51 from "../../assets/pink (7).jpg";
import model52 from "../../assets/pink (8).jpg";
import model53 from "../../assets/pink (9).jpg";
import model54 from "../../assets/pink (10).jpg";
import model55 from "../../assets/pink (11).jpg";
import model56 from "../../assets/pink (12).jpg";
import model57 from "../../assets/pink (13).jpg";
import model58 from "../../assets/pink (14).jpg";
import model59 from "../../assets/pink (15).jpg";
import model60 from "../../assets/pink (16).jpg";
import model61 from "../../assets/pink (17).jpg";
import model62 from "../../assets/pink (18).jpg";
import model63 from "../../assets/pink (19).jpg";
import model64 from "../../assets/pink (20).jpg";
import model65 from "../../assets/pink (21).jpg";
import model66 from "../../assets/pink (22).jpg";
import model67 from "../../assets/pink (23).jpg";
import model68 from "../../assets/pink (24).jpg";
import model69 from "../../assets/pink (25).jpg";
import model70 from "../../assets/pink (26).jpg";
import model71 from "../../assets/pink (27).jpg";
import model72 from "../../assets/pink (28).jpg";
import model73 from "../../assets/pink (29).jpg";
import model74 from "../../assets/pink (30).jpg";
import model75 from "../../assets/pink (31).jpg";
import model76 from "../../assets/pink (32).jpg";
import model77 from "../../assets/pink (33).jpg";
import model78 from "../../assets/pink (34).jpg";
import model79 from "../../assets/pink (35).jpg";
import model80 from "../../assets/pink (36).jpg";
import model81 from "../../assets/pink (37).jpg";
import model82 from "../../assets/pink (38).jpg";
import model83 from "../../assets/pink (39).jpg";
import model84 from "../../assets/pink (40).jpg";
import model85 from "../../assets/pink (41).jpg";
import model86 from "../../assets/pink (42).jpg";
import model87 from "../../assets/pink (43).jpg";
import model88 from "../../assets/pink (44).jpg";
import model89 from "../../assets/pink (45).jpg";
import model90 from "../../assets/pink (46).jpg";
import model91 from "../../assets/pink (47).jpg";
import model92 from "../../assets/pink (48).jpg";
import model93 from "../../assets/pink (49).jpg";
import model94 from "../../assets/pink (50).jpg";
import model95 from "../../assets/pink (51).jpg";
import model96 from "../../assets/pink (52).jpg";
import model97 from "../../assets/pink (53).jpg";
import model98 from "../../assets/pink (54).jpg";
import model99 from "../../assets/pink (55).jpg";
import model100 from "../../assets/pink (56).jpg";
import model101 from "../../assets/pink (57).jpg";
import model102 from "../../assets/pink (58).jpg";
import model104 from "../../assets/pink (60).jpg";
import model105 from "../../assets/pink (61).jpg";

function Gallery() {

  return (
    <div id="gallery">
      <header className="App-header">
        <div className="container my-5">
          <div className="row">
            <div className="col">
            <Typography
              variant="h4"
              align="center"
              sx={{
                mb: 4,
                fontFamily: 'cursive',
                fontSize: '2.5rem',
                color: '#fff', // Set the color to white

              }}
            >
              Gallery
            </Typography>


            </div>
          </div>
          <div className="row">

          <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model45} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model46} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model47} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model48} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model49} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model50} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model51} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model52} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model53} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model54} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model55} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model56} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model57} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model58} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model59} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model60} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model61} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model62} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model63} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model64} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model65} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model66} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model67} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model68} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model69} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model70} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model71} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model72} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model73} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model74} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model75} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model76} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model77} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model78} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model79} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model80} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model81} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model82} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model83} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model84} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model85} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model86} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model87} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model88} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model89} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model90} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model91} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model92} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model93} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model94} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model95} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model96} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model97} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model98} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model99} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model100} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model101} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model102} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model104} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <div className="ratio ratio-1x1">
                <img src={model105} className="img-fluid rounded" alt="Pink Love Makeup Salon" />
              </div>
            </div>
        </div>
        </div>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 3, mb: 2 }}>
              <Button
                variant="contained"
                component={Link}
                to="/images"
                color="primary"
              >
                View More
              </Button>
          </Box>
      </header>
     
    </div>
  );
}

export default Gallery;
